<template>
  <div class="page">
    <el-form :model="formInfo" label-width="120px" :rules="rules" ref="formInfo">
      <el-card class="no-hidden">
        <template slot="header">
          <span class="title">基础信息</span>
          <div class="button-back">
            <el-button @click="() => {
              $router.go(-1);
            }
              " size="small" class="back_btn">
              <img src="@/assets/back.png" alt="" />
            </el-button>
          </div>
        </template>
        <div class="card-body">
          <el-row type="flex" justify="space-between">
            <el-col :span="6">
              <el-form-item label="应用类型" prop="app_type_shop_type_flag">
                <el-select size="small" placeholder="请选择应用类型" v-model="formInfo.app_type_shop_type_flag" filterable
                  clearable style="width: 100%" @change="changeAppType" :disabled="isEdit">
                  <el-option v-for="item in filterdouble" :key="item.value" :value="item.value" :label="item.label" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between">
            <el-col :span="8">
              <el-form-item label="客户名称" prop="client_name">
                <el-autocomplete style="width: 100%" placeholder="请输入客户名称" :fetch-suggestions="queryClientSearchAsync"
                  size="small" :maxlength="64" v-model.trim="formInfo.client_name" :disabled="disabled"
                  clearable></el-autocomplete>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="代理名称">
                <el-autocomplete placeholder="代理名称" style="width: 100%" :trigger-on-focus="false"
                  @focus="returnMatchSuggestion('AGENCY')" :fetch-suggestions="agentScreen" size="small"
                  v-model.trim="formInfo.agent_name" :disabled="disabled" clearable></el-autocomplete>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系人名称" prop="link_name">
                <el-autocomplete placeholder="联系人名称" style="width: 100%" :trigger-on-focus="false"
                  @focus="returnMatchSuggestion('LINKMAN')" :fetch-suggestions="linkNameScreen" size="small"
                  v-model.trim="formInfo.link_name" :disabled="disabled" clearable></el-autocomplete>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between">
            <el-col :span="8">
              <el-form-item label="联系电话" :error="phoneError" prop="link_phone">
                <el-autocomplete placeholder="联系电话" style="width: 100%" :trigger-on-focus="false"
                  @focus="returnMatchSuggestion('PHONE')" :fetch-suggestions="phoneScreen" size="small"
                  @change="handlePhoneChange" @select="phoneSelect" v-model.trim="formInfo.link_phone"
                  :disabled="disabled" clearable></el-autocomplete>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="销售人员" prop="sell_name">
                <en-sale-search placeholder="请输入销售人员" v-model="formInfo.sell_name" :disabled="disabled"
                  @change="handleSelectSell" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="制单人名称">
                <el-autocomplete style="width: 100%" :trigger-on-focus="false" placeholder="请输入制单人名称"
                  @focus="returnMatchSuggestion('WRIGHT')" :fetch-suggestions="makeOrderScreen" size="small"
                  v-model.trim="formInfo.make_order_name" :disabled="true" clearable></el-autocomplete>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="客户地址" prop="client_addr">
            <el-autocomplete style="width: 100%" placeholder="请输入客户地址" :maxlength="255" :trigger-on-focus="false"
              @focus="returnMatchSuggestion('ADDRESS')" :fetch-suggestions="addressScreen" size="small"
              v-model.trim="formInfo.client_addr" :disabled="disabled" clearable></el-autocomplete>
          </el-form-item>
        </div>
      </el-card>
      <el-card>
        <template slot="header">
          <span class="title">卡券信息</span>
        </template>
        <div class="card-body-1">
          <el-form-item label="录入卡号">
            <div class="card-exp-area">
              <el-form-item label="所属商城">
                <el-tooltip :content="formInfo.shop_name" :disabled="(formInfo.shop_name || '').length < 12"
                  placement="top" effect="light"><el-select size="small" v-model="formInfo.shop_id" filterable clearable
                    style="width: 200px" @change="handleShopChange" @focus="handleShopFocus" :disabled="disabled">
                    <el-option v-for="(item, index) in shopList" :key="index" :value="item.shop_id"
                      :label="item.shop_name" />
                  </el-select>
                </el-tooltip>
              </el-form-item>

              <!-- 卡号上传 -->
              <card-upload ref="cul" :shopId="formInfo.shop_id" :appType="formInfo.app_type_shop_type_flag"
                @submit="exportCard" />
              <!-- 卡号上传 -->
            </div>
          </el-form-item>

          <!-- 卡券信息 -->
          <card-list ref="card_list" @change="handleChangeCardList" @range="changeCardRange" :shopData="shopList"
            :cardData="formInfo.cardList" :appType="formInfo.app_type_shop_type_flag" :disabled="disabledColumn" />
          <!-- 卡券信息 -->

          <el-row style="width: 55%">
            <el-col :span="4">
              <el-form-item label="合计总金额">
                <el-input-number size="small" :controls="false" :precision="2" style="width: 200px"
                  v-model="formInfo.total_price" disabled></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="4" :offset="11">
              <el-form-item label="优惠后总金额">
                <el-input-number size="small" :controls="false" :precision="2" style="width: 200px"
                  v-model="formInfo.after_discount_price" disabled></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="备注信息">
            <el-input size="small" type="textarea" placeholder="请输入200字以内的备注信息" rows="5" resize="none"
              v-model.trim="formInfo.remark" :maxlength="200" :disabled="disabled" />
          </el-form-item>
          <el-form-item align="center" style="width: 80%">
            <el-button type="primary" :disabled="saveDisabled" @click="isPermission">保存</el-button>
          </el-form-item>
        </div>
      </el-card>
    </el-form>
  </div>
</template>

<script>
import * as API_saleCard from "@/api/saleCard";
import money from "@/views/finance/money";
import mixins from "./saleOrderRefundMixins";
import * as API_Order from "@/api/order";
import { asyncRouterMap } from "@/router";
import cardList from "@/views/tools/sale-card-open/components/cardList";
import cardUpload from "@/views/tools/sale-card-open/components/cardUpload";

export default {
  name: "addSaleOrderRecord",
  mixins: [mixins],
  components: {
    cardList,
    cardUpload,
  },
  data () {
    return {
      double_choice_mall_open: true,//是否开启双选商城
      isEdit: !!this.$route.query.id,
      id: this.$route.query.id,
      disabled: !!this.$route.query.disabled,
      textarea: "",
      shop_card_info_list: [],
      formInfo: {
        app_type_shop_type_flag: "",
        shop_id: "",
        shop_name: "",
        client_name: "",
        agent_name: "",
        link_name: "",
        link_phone: "",
        client_addr: "",
        sell_name: "",
        make_order_name: !this.$route.query.id ? (this.$store.getters.user.nickname || "") : "",
        cardList: [],
        total_price: 0,
        after_discount_price: 0,
        remark: "",
      },
      rules: {
        app_type_shop_type_flag: [
          {
            required: true,
            message: "请选择应用类型",
            trigger: ["blur", "change"],
          },
        ],
        client_name: [
          {
            required: true,
            message: "请输入客户名称",
            trigger: ["blur", "change"],
          },
        ],
        sell_name: [
          {
            required: true,
            message: "请输入销售人员名称",
            trigger: ["blur", "change"],
          },
        ],
        sell_name: [
          {
            required: true,
            message: "请输入销售人员名称",
            trigger: ["blur", "change"],
          }
        ],
        link_name: [
          {
            required: true,
            message: "请输入联系人名称",
            trigger: ["blur", "change"],
          }
        ],
        link_phone: [
          {
            required: true,
            message: "请输入联系电话",
            trigger: ["blur", "change"],
          }
        ],
        client_addr: [
          {
            required: true,
            message: "请输入客户地址",
            trigger: ["blur", "change"],
          }
        ],
        // shop_id: [
        //   { required: true, message: "请选择所属商城", trigger: "blur" },
        // ],
      },
      saveDisabled: false,
      shopCardList: [],
      tempCardList: [], // 匹配后的卡券列表
      phoneError: "",
      disabledCardNum: false, // 禁用卡券数量
      trade_sn: "",
      disabledColumn: ["面值/价位"]
    };
  },
  computed: {
    filterdouble () {
      let cardTypes = this.cardTypes;
      if (!this.double_choice_mall_open) {
        cardTypes = this.cardTypes.filter(item => item.value !== 2)
      }
      return cardTypes;
    }
  },
  mounted () {
    API_Order.getShopExtAuth().then(resStatus => {//是否开启双选商城
      if (resStatus) {
        this.double_choice_mall_open =
          resStatus.double_choice_mall_open === "OPEN";
      }
    });
    this.checkPermission();
    this.getClientList();
    this.getDataInfo();
  },
  methods: {
    getDataInfo () {
      if (!this.isEdit) return;
      API_saleCard.getSaleOrderDetail2(this.id).then((res) => {
        const {
          app_type_shop_type_flag,
          client_name,
          agent_name,
          link_name,
          link_phone,
          client_addr,
          sell_name,
          make_order_name,
          total_price,
          after_discount_price,
          remark,
          shop_card_info_list,
          trade_sn,
        } = res;
        this.trade_sn = trade_sn;
        this.getShopList(app_type_shop_type_flag);
        this.shop_card_info_list = [...shop_card_info_list];
        const cardList = [...shop_card_info_list]
          .map((shop) => {
            let list = [];
            shop.card_range_list.forEach((item) => {
              list.push({
                ...item,
                unify_discount_rate: +shop.unify_discount_rate,
                unify_discount_rate_status: !!shop.unify_discount_rate_status,
              });
            });
            return [...list];
          })
          .flat();
        this.formInfo = {
          app_type_shop_type_flag,
          client_name,
          agent_name,
          link_name,
          link_phone,
          client_addr,
          sell_name,
          make_order_name,
          total_price,
          after_discount_price,
          remark,
          cardList,
        };

        if (this.disabled) {
          this.disabledColumn = [
            "所属商城",
            "卡券名称",
            "面值/价位",
            "卡号段",
            "数量",
          ];
        }
      });
    },
    changeAppType (v) {
      this.$nextTick(() => {
        this.formInfo.cardList = [];
        this.shop_card_info_list = [];
        this.$refs.card_list.clear();
        this.$refs.cul.clear();
      });
      this.formInfo.shop_id = "";
      this.formInfo.shop_name = "";
      if (v) {
        this.getShopList(this.formInfo.app_type_shop_type_flag);
      } else {
        this.shopCardList = [];
        this.shopList = [];
      }
    },
    setRangeCardList ({ item, index, cards }, res) {
      const { shop_id, shop_name, app_type_shop_type_flag } = item;
      let discount_ratio_org = item.discount_ratio;
      cards[shop_id] = res.card_range_list;
      // console.log(item);
      this.shop_card_info_list = [];

      for (const [key, value] of Object.entries(cards)) {
        if (key != "") {
          let _value = [...value];
          // const { card_code_start, card_code_end, card_num } = _value[index];
          // console.log(_value[index]);
          if (key == shop_id) {
            _value[index] = {
              ..._value[index],
            };
          }
          let sitem = value.find((v) => v.shop_id == key);
          this.shop_card_info_list.push({
            shop_id: key,
            shop_name: sitem ? sitem.shop_name : "",
            app_type_shop_type_flag,
            card_range_list: _value,
          });
        }
      }
      let cardList = this.shop_card_info_list
        .map((shop) => {
          let list = [];
          let first = shop.card_range_list[0];
          shop.card_range_list.forEach((item) => {
            let discount_ratio =
              item.discount_ratio == null
                ? discount_ratio_org
                : item.discount_ratio;
            if (first.unify_discount_rate_status) {
              discount_ratio = +first.unify_discount_rate;
            }
            list.push({
              ...item,
              unify_discount_rate: +first.unify_discount_rate,
              unify_discount_rate_status: !!first.unify_discount_rate_status,
              discount_ratio,
            });
          });
          return [...list];
        })
        .flat();



      let nlist = cards[""];
      let clist = [...cardList];
      if (nlist) {
        clist.push(nlist[0]);
      }
      this.formInfo.cardList = clist;
    },
    async changeCardRange ({ item, index, list, cards, type }) {
      const { shop_id, shop_name } = item;
      const { app_type_shop_type_flag } = this.formInfo;
      const { trade_sn } = this;
      let params = {
        shop_id,
        shop_name,
        app_type_shop_type_flag,
        card_range_index: type == "num" ? null : index,
        card_range_list: [...list],
        change_num_card_id: item.card_id,
      };
      if (this.isEdit) params["trade_sn"] = trade_sn;

      API_saleCard.editNewCardCouponInfo(params)
        .then((res) => {
          if ((res.not_inactive_card_range || []).length > 0) {
            this.checkCardRange([...res.not_inactive_card_range], 1).then(
              (type) => {
                if (type == 1) {
                  this.setRangeCardList({ item, index, cards }, res);
                } else {
                  this.$refs.card_list.backtrack();
                }
              }
            );
            return;
          }
          this.setRangeCardList({ item, index, cards }, res);
        })
        .catch((err) => {
          console.error(err);
          this.$refs.card_list.backtrack();
        });
    },
    exportCard (params) {
      const shop_card_info_list = this.$refs.card_list.getShopCardInfoList();
      let cards = this.$refs.card_list.getCardData();
      if (!this.checkCardSave(cards)) {
        return this.$message.error("请完善卡券信息");
      }
      console.log(params);
      API_saleCard.autoIdentify({
        ...params,
        shop_card_info_list,
      }).then((res) => {
        if (!this.checkCardExportRules(res)) return;
        const { shop_card_info_list, shop_id, app_type_shop_type_flag } = res;
        let shop_name = this.shopList.find(
          (s) => s.shop_id == shop_id
        ).shop_name;
        this.shop_card_info_list = shop_card_info_list;
        let cardList = this.shop_card_info_list
          .map((shop) => {
            let list = [];
            shop.card_range_list.forEach((item) => {
              list.push({
                ...item,
                app_type_shop_type_flag,
                unify_discount_rate_status: !!item.unify_discount_rate_status,
                unify_discount_rate: +item.unify_discount_rate,
              });
            });
            return [...list];
          })
          .flat();
        this.formInfo.cardList = cardList;
      });
    },
    handleChangeCardList ({ list, totalPrice, discountPrice }) {
      this.cardList = list;
      this.formInfo.total_price = totalPrice;
      this.formInfo.after_discount_price = discountPrice;
    },
    handlePhoneChange (val) {
      const phoneReg =
        /^0?(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$/;
      if (phoneReg.test(val)) {
        this.phoneError = "";
      } else if (val && !phoneReg.test(val)) {
        this.phoneError = "手机号格式错误";
      } else {
        this.phoneError = "";
      }
    },
    // 获取商城的卡券列表
    getShopCardList (val) {
      API_saleCard.getShopCardList(val, this.formInfo.app_type_shop_type_flag).then((res) => {
        this.shopCardList = res.map((item) => {
          return {
            value: item.id,
            label: item.card_name,
          };
        });
      });
    },
    // 商城下拉列表change事件
    handleShopChange (val) {
      this.formInfo.shop_name = this.getShopName(val)
    },
    handleShopFocus (val) {
      if (!this.formInfo.app_type_shop_type_flag) {
        return this.$message.error("请先选择应用类型");
      }
      // this.getShopList();
    },
    // 计算总金额和优惠后总金额
    // totalPriceAndDiscountPrice() {
    //   this.formInfo.total_price = 0;
    //   this.formInfo.after_discount_price = 0;
    //   this.formInfo.cardList.forEach((i) => {
    //     let total = i.card_num * i.card_value;
    //     this.formInfo.total_price += total;
    //     this.formInfo.after_discount_price += parseFloat(
    //       (i.money ? i.money : 0).toFixed(2)
    //     );
    //   });
    // },
    // 判断是否有销售开卡权限
    isPermission () {
      if (this.saveDisabled) return
      this.saveDisabled = true
      API_Order.getShopExtAuth().then((res) => {
        if (res.open === "OPEN") {
          this.save();
        } else {
          this.$message.error(
            "平台已关闭销售开卡功能，如有其他问题，请联系平台。"
          );
          setTimeout(() => {
            this.handleNoPermission(asyncRouterMap);
          }, 2000);
        }
      });
    },
    // 保存
    save () {
      // let cards = this.$refs.card_list.getCardData();
      // if (!this.checkCardSave(cards,true)) {
      //   return this.$message.error("请完善卡券信息");
      // }
      // 校验表单
      this.$refs.formInfo.validate((validate) => {
        if (validate) {
          const {
            app_type_shop_type_flag,
            agent_name,
            client_addr,
            client_name,
            link_name,
            link_phone,
            sell_name,
            salesman_id,
            make_order_name,
            remark,
            after_discount_price,
            total_price,
            shop_id,
          } = this.formInfo;
          let shop_card_info_list = [];
          let data = this.$refs.card_list.getCardData();
          for (const key in data) {
            if (key == "") return this.saveDisabled = false, this.$message.error("请完善卡券信息");
            let sitem = data[key].find((v) => v.shop_id == key);
            let shop_item = {
              shop_id: key,
              shop_name: sitem ? sitem.shop_name : "",
              card_range_list: [],
            };
            for (let i = 0; i < data[key].length; i++) {
              const d = data[key][i];
              if (
                d.card_id == "" ||
                d.card_code_end == "" ||
                d.card_code_start == ""
              ) {
                return this.saveDisabled = false, this.$message.error("请完善卡券信息");
              }
              const { discount_ratio, card_value, card_num } = d;
              const discount_value = this.roundNum(
                card_num * card_value * (1 - discount_ratio / 100)
              );
              const after_discount_price = this.roundNum(
                (card_num * card_value * discount_ratio) / 100
              );

              shop_item.card_range_list.push({
                ...d,
                // app_type,
                discount_value,
                after_discount_price,
                unify_discount_rate_status: +d.unify_discount_rate_status,
                unify_discount_rate: +d.unify_discount_rate,
              });
            }

            shop_card_info_list.push(shop_item);
          }
          // console.log(shop_card_info_list)

          let params = {
            app_type_shop_type_flag,
            agent_name,
            client_addr,
            client_name,
            link_name,
            link_phone,
            make_order_name,
            remark,
            sell_name,
            salesman_id,
            after_discount_price,
            total_price,
            shop_id,
            shop_card_info_list,
          };
          if (this.isEdit) {
            API_saleCard.editTradeSell2(
              {
                ...params,
                trade_sn: this.trade_sn,
              },
              this.id
            ).then((res) => {
              this.$message.success("修改成功");
              this.$router.go(-1);
            });
          } else {
            API_saleCard.addTradeSell(params).then((res) => {
              this.$message.success("添加成功");
              this.$router.go(-1);
            });
            // .catch((err) => {
            //   if (err.response.data.code !== "301") {
            //     this.$router.go(-1);
            //   }
            // });
          }
        } else {
          this.saveDisabled = false
          this.$message.error("请检查必填项");
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "./style/index.scss";
</style>
